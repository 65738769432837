import * as Models from "@gigalot/data-models";

class AnimalQueue {

  queue: Models.Animal[] = [];
  itemCallback: ((animal: Models.Animal) => Promise<void>) | undefined = undefined;
  queueFinishedCallback: (() => Promise<void>) | undefined = undefined;
  isBusy = false;

  hasCallback() {
    return this.itemCallback !== undefined;
  }

  //Callback that is called per item as queue is processed in processQueue()
  setItemCallback(cb: (animal: Models.Animal) => Promise<void>) {
    this.itemCallback = cb;
  }

  setQueueFinishedCallback(cb: () => Promise<void>) {
    this.queueFinishedCallback = cb;
  }

  enqueue(i: Models.Animal | Models.Animal[]) {
    if (Array.isArray(i)) this.queue.push(...i);
    else this.queue.push(i);
    this.processQueue();
  }

  dequeue() {
    const ret = this.queue.shift();
    return ret;
  }

  async processQueue() {
    console.log("processQueue")
    if (!this.itemCallback) throw Error("No callback given to animal queue.")
    if (this.isBusy) return;
    this.isBusy = true;
    let a: Models.Animal | undefined;
    console.log("calling itemCallback for queue items")
    while (a = this.dequeue()) {
      await this.itemCallback?.(a)
    }
    console.log("done calling itemCallback")
    this.queueFinishedCallback?.();
    this.isBusy = false;
    console.log("finished processing queue")
  }

  clear() {
    this.queue = [];
  }

}

let animalQueue = new AnimalQueue();

export { animalQueue };
