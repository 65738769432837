import * as Models from "@gigalot/data-models"
import { AnimalListItem } from "@/models/animal-list-item"

let isBusy: boolean = true;

let animalListItems: AnimalListItem[] = [];

let promises: { resolve: any, reject: any }[] = [];

function resolve() {
  for (const p of promises) {
    p.resolve(animalListItems);
  }
}

function animalToListItem(a: Models.Animal & { visualSgtin?: string }): AnimalListItem {
  return {
    breed: a.breed,
    gender: a.gender,
    sgtin: a.sgtin,
    customFeeder: a.customFeeder,
    visualSgtin: a.visualSgtin,
  };
}

export async function initialize(store: any) {
  isBusy = true;
  animalListItems = await store.dispatch("dataManager/mapOverAnimals", (a: Models.Animal & { visualSgtin?: string }) => animalToListItem(a));
  resolve();
  isBusy = false;
}

export function getAnimalListItems() {
  if (!isBusy) return animalListItems;
  else {
    return new Promise<AnimalListItem[]>((resolve, reject) => {
      promises.push({ resolve, reject })
    })
  }
}

export function clearAnimalListItems() {
  animalListItems = [];
}

export function addAnimalsAsTheyDownload(i: Models.Animal | Models.Animal[]) {
  console.log("addAnimalsAsTheyDownload, i:", JSON.stringify(i));
  isBusy = true;

  if (!Array.isArray(i)) i = [i];
  for (const a of i) {
    if (a === undefined) {
      console.warn("WARNING!!! addAnimalsAsTheyDownload given undefined animal, skipping...");
      continue;
    }
    const listItem = animalToListItem(a);
    const index = animalListItems.findIndex(ali => ali.sgtin === a.sgtin)
    if (index >= 0) animalListItems[index] = listItem;
    else animalListItems.push(listItem);
  }

  isBusy = false;
}

export function removeAnimal(sgtin: string) {
  let index = animalListItems.findIndex(a => a.sgtin === sgtin)
  if (index < 0) return;
  animalListItems.splice(index, 1);
}